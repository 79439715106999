import {
  AddressInfo,
  Checkout as DrCheckout,
  CreateCheckout,
  CreateItem,
  UpdateCheckout,
} from '@services/DigitalRiver/types';
import { PaypalExpressCheckout } from '@services/paypal/types';
import { AuthorizeCheckoutBody } from '@services/paypal/types/AuthorizeCheckoutInput';
import { ExpressCheckoutBody } from '@services/paypal/types/ExpressCheckoutInput';
import type { CanMakePaymentResult } from '@stripe/stripe-js/types';
import merge from 'lodash/merge';

import { Cart } from '@commerce/types/cart';
import { Prices } from '@components/cart/types/Prices';
import { Address } from '@components/common/types/Address';
import { ProductBrand, ProductType } from '@components/product/enums';
import { SelectOption } from '@components/ui/Select/Select';
import skuGroupIds from '@config/digital-sku-group-mapping.json';
import { CreateAddress, UpdateAddress } from '@framework/api/utils/bigcommerce/checkout';
import { CHECKOUT_DATA_LOCAL_STORAGE, ORDER_METADATA_SESSION_STORAGE } from '@framework/const';
import { BillingAddress } from '@framework/types/billing';
import { BigCommerceCartLineItem } from '@framework/types/cart';
import { BigCommerceCheckout } from '@framework/types/checkout';
import { ConsignmentAddress } from '@framework/types/consignment';
import { Country, CountryStates } from '@framework/types/country-state';
import callApiRoutes from '@lib/api/call-api-routes';
import { getStorage, setStorage } from '@lib/browser-storage';
import { Nil } from '@lib/utility-types';

import { CheckoutSteps } from './enums/CheckoutSteps';
import { PaymentMethodType } from './enums/PaymentMethodType';
import { ShippingMethod } from './enums/ShippingMethod';
import { WalletType } from './enums/WalletType';
import { CheckoutStorageData } from './types/CheckoutStorageData';
import { GiftOptions, UserInfo, UserInfoPayload } from './types/UserInfo';

export const checkoutOrder = [
  CheckoutSteps.CUSTOMER,
  CheckoutSteps.SHIPPING,
  CheckoutSteps.DELIVERY,
  CheckoutSteps.PAYMENT,
  CheckoutSteps.REVIEW,
];

export const drCheckoutOrder = [
  CheckoutSteps.ADDRESS,
  CheckoutSteps.DELIVERY,
  CheckoutSteps.PAYMENT,
  CheckoutSteps.REVIEW,
];

export function toCents(num: number) {
  // Typescript/JS is very strange with forcing 2 decimals after calculations
  return parseInt(num.toFixed(2).replace('.', ''), 10);
}

export function toDollar(cents: number) {
  return parseFloat(String(cents / 100));
}

export const getAddress = (address?: ConsignmentAddress | null, countryCode?: string): Address => ({
  firstName: address?.first_name || '',
  lastName: address?.last_name || '',
  address1: address?.address1 || '',
  address2: address?.address2 || '',
  zipCode: address?.postal_code || '',
  state: address?.state_or_province || '',
  stateCode: address?.state_or_province_code || '',
  city: address?.city || '',
  country: countryCode || address?.country_code || '',
  phone: address?.phone || '',
});

// following the exact way order consumer is generating fullName for netsuite payload
export const getFullName = (firstName?: string, lastName?: string) => `${firstName || ''} ${lastName || ''}`.trim();

export const isAddressValid = (
  { address1, zipCode, stateCode, city, country, firstName, lastName }: Omit<Address, 'state'>,
  options?: { omitState: boolean }
): boolean => {
  const { omitState } = options || {};
  const valid = !!(address1 && zipCode && city && country && firstName && lastName);

  if (omitState) {
    return valid;
  }
  return valid && !!stateCode;
};

export function getUserInfo({ email, countryCode, checkout, gifts }: UserInfoPayload): UserInfo {
  return {
    email: checkout?.consignments[0]?.shipping_address.email || email || '',
    consignmentId: checkout?.consignments[0]?.id || '',
    shipping: getAddress(checkout?.consignments[0]?.shipping_address, countryCode),
    deliveryMethodId: checkout?.consignments[0]?.selected_shipping_option?.id || '',
    deliveryMethodName: checkout?.consignments[0]?.selected_shipping_option?.description || '',
    paymentMethodType: '',
    stripe: {
      paymentMethodId: '',
      cardType: '',
      cardExpDate: '',
      cardLastFour: '',
    },
    billingId: checkout?.billing_address?.id || '',
    billingAddressType: 'same',
    billing: getAddress(checkout?.billing_address, countryCode),
    orderId: 0,
    checkoutId: checkout?.id || '',
    giftOptions: gifts || {
      giftMessage: '',
      recipientEmail: '',
    },
    maxStepReached: checkoutOrder[0],
  };
}

// only set deliveryMethodId and deliveryMethodName if selected_shipping_option is within available_shipping_options
export function getDeliveryMethod(checkout?: BigCommerceCheckout): { id: string; name: string } {
  if (!checkout || !checkout?.consignments?.[0]?.selected_shipping_option?.id) {
    return { id: '', name: '' };
  }

  const availableShipping = checkout?.consignments?.[0]?.available_shipping_options || [];
  const selected = checkout?.consignments?.[0]?.selected_shipping_option;

  if (!availableShipping.find(({ id }) => id === selected.id)) {
    return { id: '', name: '' };
  }

  return { id: selected?.id || '', name: selected?.description || '' };
}

export function getPartialUserInfo({ email, countryCode, checkout }: UserInfoPayload): Partial<UserInfo> {
  const deliveryMethod = getDeliveryMethod(checkout);

  return {
    email: email || checkout?.consignments[0]?.shipping_address.email,
    consignmentId: checkout?.consignments[0]?.id || '',
    shipping: checkout?.consignments[0]?.shipping_address
      ? getAddress(checkout?.consignments[0]?.shipping_address)
      : getAddress(undefined, countryCode),
    deliveryMethodId: deliveryMethod.id,
    deliveryMethodName: deliveryMethod.name,
    billingId: checkout ? checkout?.billing_address?.id || '' : undefined,
    billing: checkout?.billing_address ? getAddress(checkout?.billing_address) : getAddress(undefined, countryCode),
    checkoutId: checkout?.id || '',
  };
}

export const getUserInfoCreatedDate = (locale: string): string => {
  const curData = getStorage<CheckoutStorageData>(getStorageKey('userInfo', locale));
  return (curData && typeof curData !== 'string' && curData.created) || new Date().toISOString();
};

const cleanCheckoutStorageData = (curData: CheckoutStorageData): CheckoutStorageData => {
  const { created = new Date().toISOString(), userInfo, lastUpdate } = curData;
  const { paymentMethodType } = userInfo;
  const lifetime = new Date().getTime() - new Date(created).getTime();

  // set all payment info TTL to 1 hour
  if (lifetime < 3600 * 1000) {
    return { userInfo, created, lastUpdate };
  }

  let cleanedUserInfo = { ...userInfo };
  let createdDate = created;

  switch (paymentMethodType) {
    case PaymentMethodType.CREDIT_CARD: {
      cleanedUserInfo = merge(userInfo, {
        paymentMethodType: '',
        stripe: {
          paymentMethodId: '',
          cardType: '',
          cardExpDate: '',
          cardLastFour: '',
        },
      });
      createdDate = new Date().toISOString();
      break;
    }
    case PaymentMethodType.PAYPAL: {
      cleanedUserInfo = merge(userInfo, {
        paymentMethodType: '',
        paypal: null,
      });
      createdDate = new Date().toISOString();
      break;
    }
    default:
      break;
  }

  return { userInfo: cleanedUserInfo, created: createdDate, lastUpdate: new Date().toISOString() };
};

export const getCheckoutDataFromStorage = (locale: string, updateStorage = false): CheckoutStorageData | null => {
  const curData = getStorage<CheckoutStorageData>(getStorageKey('userInfo', locale));

  if (curData && typeof curData !== 'string' && curData?.userInfo) {
    const cleanedCheckoutData = cleanCheckoutStorageData(curData);

    if (updateStorage) {
      setStorage(
        getStorageKey('userInfo', locale),
        {
          userInfo: cleanedCheckoutData.userInfo,
          created: cleanedCheckoutData.created,
          lastUpdate: new Date().toISOString(),
        },
        { manualTrigger: false }
      );
    }

    return cleanedCheckoutData;
  }

  return null;
};

export function isBillingSameAsShipping({ billingAddressType }: Pick<UserInfo, 'billingAddressType'>) {
  return billingAddressType === 'same';
}

export function getPreviousStep(current: CheckoutSteps): CheckoutSteps | null {
  const curIndex = checkoutOrder.indexOf(current);
  return checkoutOrder[curIndex - 1] || null;
}

export function getNextStep(current: CheckoutSteps): CheckoutSteps | null {
  const curIndex = checkoutOrder.indexOf(current);
  return checkoutOrder[curIndex + 1] || null;
}

// TODO to be deleted/refactored, will never skip a Step anymore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function hasPassedStep(currentStep: CheckoutSteps, maxStepReached: CheckoutSteps): boolean {
  return false;
  // if (currentStep === maxStepReached) {
  //   return false;
  // }

  // return checkoutOrder.indexOf(currentStep) < checkoutOrder.indexOf(maxStepReached);
}

/**
 * Of the two given steps, return the one that is farthest in checkout sequence.
 * @example
 * // returns CheckoutSteps.REVIEW
 * getMaxStep(CheckoutSteps.CUSTOMER, CheckoutSteps.REVIEW)
 */
export function getMaxStep(step1: CheckoutSteps, step2: CheckoutSteps): CheckoutSteps {
  return checkoutOrder.indexOf(step1) < checkoutOrder.indexOf(step2) ? step2 : step1;
}

export function getOptionValue(code: string, options: SelectOption[]): string {
  const item = options.find(({ key }) => key === code);
  return item?.value || '';
}

export function getCheckoutPrices(
  {
    consignments,
    subtotal_ex_tax: subtotal,
    subtotal_inc_tax: subtotalTaxInc,
    tax_total: checkoutTax,
    shipping_cost_total_ex_tax: shippingCost,
    shipping_cost_total_inc_tax: shippingTaxInc,
    grand_total: totalTaxInc,
  }: BigCommerceCheckout,
  discountAmt?: number | null,
  taxesIncluded?: boolean,
  isDigitalOnly = false,
  drCheckout?: DrCheckout | null
): Prices {
  const addressProvided = consignments?.length > 0;
  const shippingData = consignments?.[0];

  // when option is selected and address is changed to invalidate the selection, BigC won't clear the selected option from checkout data...
  // hence this check to ensure the selected shipping option is valid
  const shippingOptionSelected = !!(
    // First, check if the shipping option from shippingData is valid
    (
      (shippingData?.selected_shipping_option &&
        shippingData?.available_shipping_options?.find(
          ({ id }) => id === shippingData?.selected_shipping_option?.id
        )) ||
      // If the first check fails, fallback to drCheckout's shipping choice
      drCheckout?.shippingChoice?.id
    )
  );
  const shipping = taxesIncluded ? shippingTaxInc : shippingCost;
  const tax = addressProvided || isDigitalOnly || taxesIncluded ? checkoutTax : undefined;
  const discount = typeof discountAmt === 'number' ? discountAmt : undefined;

  // for tax exclusive store, `grand_total` field will include default tax which will be incorrect/confusing to display (when shipping hasn't been provided)
  // hence this calculation
  const total = subtotal - (discount || 0) + (shipping || 0) + (tax || 0);

  return {
    total: taxesIncluded || addressProvided ? totalTaxInc : total,
    tax,
    subtotal: taxesIncluded ? subtotalTaxInc : subtotal,
    shipping: (addressProvided && shippingOptionSelected) || isDigitalOnly ? shipping : undefined,
    discount,
  };
}

export function getCheckoutPricesWithDR(
  checkout: BigCommerceCheckout,
  drCheckout?: DrCheckout | null,
  discountAmt?: number | null,
  taxesIncluded?: boolean,
  isDigitalOnly = false
): Prices {
  const pricesFromBigC = getCheckoutPrices(checkout, discountAmt, taxesIncluded, isDigitalOnly, drCheckout);
  const importFees = drCheckout?.totalImporterTax;

  function calculateTotal() {
    const path = window?.location?.pathname;
    // If drCheckout totalAmount is available, calculate using it
    if (
      drCheckout?.totalAmount !== undefined &&
      drCheckout?.totalAmount !== null &&
      (path.includes('/payment') || path.includes('/review'))
    ) {
      return drCheckout.totalAmount;
    }

    // Otherwise, calculate using pricesFromBigC total and importFees
    return (pricesFromBigC.total ?? 0) + (importFees ?? 0);
  }

  return {
    ...pricesFromBigC,
    importFees,
    tax: drCheckout?.totalTax ?? pricesFromBigC.tax,
    total: calculateTotal(),
  };
}

export function getSelectOptions({
  countries,
  states,
  allCountries,
}: CountryStates & { allCountries?: Country[] }): Record<string, SelectOption[]> {
  return {
    countries: countries.map(({ countryCode: key, country: value }) => ({ key, value })),
    allCountries: allCountries?.map(({ countryCode: key, country: value }) => ({ key, value })) || [],
    states: states.map(({ stateCode: key, state: value }) => ({ key, value })),
  };
}

export function getStorageKey(type: 'metadata' | 'userInfo', locale: string) {
  switch (type) {
    case 'userInfo':
      return `${locale}_${CHECKOUT_DATA_LOCAL_STORAGE}`;
    case 'metadata':
      return `${locale}_${ORDER_METADATA_SESSION_STORAGE}`;
    default:
      return '';
  }
}

export function getAddressPayload<T = BillingAddress>(
  { firstName, lastName, address1, address2, city, stateCode, country, zipCode, phone }: Omit<Address, 'state'>,
  email: string
) {
  const payload = {
    first_name: firstName,
    last_name: lastName,
    email,
    address1,
    address2,
    city,
    state_or_province_code: stateCode,
    country_code: country,
    postal_code: zipCode,
    phone: phone || '',
  };

  return payload as T;
}

export function getConsignmentPayload(address: Omit<Address, 'state'>, email: string, cart?: Cart | null) {
  return {
    shipping_address: getAddressPayload<ConsignmentAddress>(address, email),
    line_items:
      cart?.lineItems.map((item) => ({
        item_id: item.id,
        quantity: item.quantity,
      })) || [],
  };
}

// for shipping address payload on order metafields
export function getAddressMetadata(
  { firstName, lastName, address1, address2, city, stateCode, state, country, zipCode, phone }: Address,
  email: string,
  shipping = ShippingMethod.FREE
) {
  return {
    first_name: firstName,
    last_name: lastName,
    email,
    street_1: address1,
    street_2: address2,
    city,
    state,
    stateCode,
    country_iso2: country,
    zip: zipCode,
    phone,
    shipping_method: shipping,
  };
}

export function parseStripeAddress(address: Address) {
  return {
    city: address.city,
    country: address.country,
    line1: address.address1,
    line2: address.address2,
    postal_code: address.zipCode,
    state: address.stateCode,
  };
}

export function parseStripeShippingAddress(shippingAddress: Address) {
  return {
    shipping: {
      address: parseStripeAddress(shippingAddress),
      name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
      phone: shippingAddress.phone,
    },
    name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
    phone: shippingAddress.phone,
  };
}

export const getWalletAddress = (userInfo: UserInfo) => {
  switch (userInfo.paymentMethodType) {
    case PaymentMethodType.PAYPAL:
      return userInfo.paypal?.email;
    // stripe wallets prob not needed as it'll go to order submit/confirmed once paid
    case PaymentMethodType.APPLE_PAY:
      return userInfo.applePay || '';
    case PaymentMethodType.GOOGLE_PAY:
      return userInfo.googlePay || '';
    default:
      return '';
  }
};

export function getIntegrationNotesFromType(
  paymentMethodType: PaymentMethodType | ''
): Record<string, string | boolean> {
  let notes: Record<string, string | boolean> = {};
  switch (paymentMethodType) {
    // deprecated for apple/google pay, it'll be using `getIntegrationNotesFromResult`
    case PaymentMethodType.APPLE_PAY:
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.APPLE_PAY };
      break;
    case PaymentMethodType.GOOGLE_PAY:
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.GOOGLE_PAY };
      break;
    case PaymentMethodType.PAYPAL: {
      notes = { payment_provider: PaymentMethodType.PAYPAL };
      break;
    }
    case PaymentMethodType.CREDIT_CARD: {
      notes = { payment_provider: PaymentMethodType.CREDIT_CARD };
      break;
    }
    case PaymentMethodType.DIGITAL_RIVER: {
      notes = { payment_provider: PaymentMethodType.DIGITAL_RIVER };
      break;
    }
    default:
      break;
  }

  return notes;
}

export function isPaymentRequired(totalPrice: number, hasPremium: boolean): boolean {
  return totalPrice > 0 || hasPremium;
}

export function getIntegrationNotesFromResult(result: CanMakePaymentResult): Record<string, string | boolean> {
  if (result.applePay) {
    return { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.APPLE_PAY };
  }
  return { payment_provider: PaymentMethodType.CREDIT_CARD, wallet: WalletType.GOOGLE_PAY };
}

export async function setPaypalExpressCheckout(
  locale: string,
  data: ExpressCheckoutBody
): Promise<{ token: string; widgetUrl: string }> {
  const resp = await callApiRoutes<{ data: { token: string; widgetUrl: string } }>(
    `/api/paypal/nvp/express-checkout?locale=${locale}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );

  return resp.data;
}

export async function getPaypalExpressCheckout(locale: string, token: string): Promise<PaypalExpressCheckout> {
  const resp = await callApiRoutes<{ data: PaypalExpressCheckout }>(
    `/api/paypal/nvp/express-checkout?locale=${locale}&token=${token}`
  );
  return resp.data;
}

export async function authorizePaypalExpressCheckout(
  locale: string,
  token: string,
  data: AuthorizeCheckoutBody
): Promise<{ transactionId: string; billingAgreementId?: string }> {
  const resp = await callApiRoutes<{ data: { transactionId: string; billingAgreementId?: string } }>(
    `/api/paypal/nvp/authorize?locale=${locale}&token=${token}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );

  return resp.data;
}

export async function createBillingAgreement(locale: string, token: string): Promise<string> {
  const resp = await callApiRoutes<{ data: string }>(
    `/api/paypal/nvp/billing-agreement?locale=${locale}&token=${token}`,
    {
      method: 'POST',
    }
  );

  return resp.data;
}

export async function checkSubscriptionStatus(locale: string): Promise<{ premiumState: boolean; freeTrial: boolean }> {
  const resp = await callApiRoutes<{ data: { premiumState: boolean; freeTrial: boolean } }>(
    `/api/customer/subscription-status?locale=${locale}`,
    {
      method: 'GET',
    }
  );

  return resp.data;
}

export function getGACommercePayload(
  orderId: string | number,
  cart: Nil<Cart>,
  currency: string,
  prices: Prices,
  locale: string,
  storeHash: string
) {
  const items =
    cart?.lineItems?.map(({ name, quantity, variant: { sku, price } }) => ({
      id: sku,
      name,
      brand: ProductBrand.TILE,
      quantity,
      price,
    })) || [];

  return {
    transaction_id: `${orderId}`,
    affiliation: 'Tile eCommerce',
    value: prices.total,
    currency: currency.toLocaleUpperCase(),
    tax: prices.tax,
    discount: prices.discount || 0,
    locale,
    storeHash,
    shipping: prices.shipping,
    items,
  };
}

export function isGiftOptionEmpty(option?: GiftOptions): boolean {
  if (!option) {
    return true;
  }

  return !option?.giftMessage && !option?.recipientEmail;
}

export const trimValue = (value?: string) => value?.trim() ?? '';

const getDrWarehouse = (locale: string) => {
  // TODO change the value to compare with enum (e.g. SUPPORTED_LOCALES.EN_GB)
  if (locale === 'en-gb') {
    return {
      address: {
        line1: 'Unit 5-9 Stretton Green Distribution Centre, Langford Way',
        city: 'Warrington',
        postalCode: 'WA4 4TQ',
        state: 'IV',
        country: 'GB',
      },
    };
  }

  return {
    address: {
      line1: 'Flex Building 11, Heierhoevenweg 10',
      city: 'Venlo',
      postalCode: '5928 RN',
      state: 'LI',
      country: 'NL',
    },
  };
};

/**
 * build DigitalRiver address payload from BigC consignment
 * @param {ConsignmentAddress} bigCAddress
 * @returns
 */
function buildDrAddress(bigCAddress: ConsignmentAddress): AddressInfo {
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    address1: line1,
    address2: line2,
    city,
    postal_code: postalCode,
    state_or_province_code: state,
    country_code: country,
    // address is already filled in when DR is created
  } = bigCAddress as Required<ConsignmentAddress>;

  return {
    name: `${firstName} ${lastName}`,
    email,
    address: {
      line1,
      line2,
      city,
      postalCode,
      state,
      country,
    },
  };
}

function getDrSkuGroupId(sku: string, locale: string): string {
  // @ts-ignore
  return skuGroupIds[locale]?.find((item: any) => item.sku === sku)?.skuGroupId || skuGroupIds.general[0];
}

function buildDrItems(lineItems: BigCommerceCartLineItem[], locale: string, type = ProductType.PHYSICAL): CreateItem[] {
  return lineItems.map(({ sku, sale_price: price, quantity, name, discounts }) => ({
    quantity,
    price,
    productDetails: {
      id: sku,
      skuGroupId: getDrSkuGroupId(sku, locale),
      name,
      description: name,
      itemBreadcrumb: type,
    },
    discount: discounts?.map((discountAmount: { id: number; discounted_amount: number }) => ({
      amountOff: discountAmount.discounted_amount / quantity,
    }))[0],
  }));
}

/**
 * to build DigitalRiver update checkout payload from BigC checkout
 * @param {BigCommerceCheckout} checkout
 * @returns
 */
export function buildDrUpdatePayload(checkout: BigCommerceCheckout, taxesIncluded: boolean): UpdateCheckout {
  const {
    consignments,
    billing_address: billing,
    shipping_cost_total_ex_tax: shippingCost,
    shipping_cost_total_inc_tax: shippingTaxInc,
  } = checkout;
  const { shipping_address: shipping, selected_shipping_option: shippingOption } = consignments[0];

  const payload: UpdateCheckout = {
    email: billing.email,
    shipTo: buildDrAddress(shipping),
    billTo: buildDrAddress(billing),
  };

  if (shippingOption && shippingOption.id) {
    payload.shippingChoice = {
      amount: taxesIncluded ? shippingTaxInc : shippingCost,
      description: shippingOption.description,
      serviceLevel: shippingOption.type,
      id: shippingOption.id,
    };
  }

  return payload;
}

/**
 * to build DigitalRiver create checkout payload from BigC checkout
 * @param {BigCommerceCheckout} checkout
 * @param {boolean} taxesIncluded
 * @returns
 */
export function buildDrCreatePayload(
  checkout: BigCommerceCheckout,
  taxesIncluded: boolean,
  locale: string,
  clientIpAddress?: string
): CreateCheckout {
  const {
    cart: {
      currency: { code: currency },
      line_items: lineItems,
    },
  } = checkout;
  return {
    ...buildDrUpdatePayload(checkout, taxesIncluded),
    currency,
    shipFrom: getDrWarehouse(locale),
    items: buildDrItems(lineItems.physical_items, locale),
    taxInclusive: taxesIncluded,
    browserIp: clientIpAddress,
  };
}

export async function getDrCheckout(locale: string): Promise<DrCheckout | null> {
  const resp = await callApiRoutes<{ data: DrCheckout }>(`/api/digital-river/checkout?locale=${locale}`, {
    method: 'GET',
  });

  return resp.data;
}

export async function createDrCheckout(locale: string, payload: CreateCheckout): Promise<DrCheckout | null> {
  const resp = await callApiRoutes<{ data: DrCheckout }>(`/api/digital-river/checkout?locale=${locale}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return resp.data;
}

export function buildCreateAddressPayload({
  email,
  shipping,
  billing,
  cart,
}: {
  email: string;
  shipping: Address;
  billing: Address;
  cart?: Cart | null;
}): CreateAddress {
  return {
    shipping: {
      consignmentData: getConsignmentPayload(shipping, email, cart),
    },
    billing: {
      billing_address: getAddressPayload(billing, email),
    },
  };
}

export async function createAddress(locale: string, payload: CreateAddress): Promise<BigCommerceCheckout> {
  const resp = await callApiRoutes<{ data: BigCommerceCheckout }>(`/api/checkout/address?locale=${locale}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return resp.data;
}

export function buildUpdateAddressPayload({
  email,
  shipping,
  consignmentId,
  billing,
  billingId,
  cart,
}: {
  email: string;
  shipping: Address;
  consignmentId: string;
  billing: Address;
  billingId: string;
  cart?: Cart | null;
}): UpdateAddress {
  return {
    shipping: {
      consignmentData: getConsignmentPayload(shipping, email, cart),
      consignmentId,
    },
    billing: {
      billing_address: getAddressPayload(billing, email),
      billing_id: billingId,
    },
  };
}

export async function updateAddress(locale: string, payload: UpdateAddress): Promise<BigCommerceCheckout> {
  const resp = await callApiRoutes<{ data: BigCommerceCheckout }>(`/api/checkout/address?locale=${locale}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return resp.data;
}
