/**
 * empty component to run check on query string for SSO/auth code login
 * register userId for logged in user on gtag
 * update order metadata (inapp, referrer) in session storage
 */

import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import { ParsedUrlQuery } from 'querystring';
import { FC, useEffect } from 'react';
import useDeepEffect from 'use-deep-compare-effect';

import { getStorageKey } from '@components/checkout/helpers';
import { GlobalQueryString } from '@components/ui/context';
import { ErrorCode } from '@framework/api/utils/error-code';
import useLogin from '@framework/auth/use-login';
import useCustomer from '@framework/customer/use-customer';
import { getStorage, setStorage } from '@lib/browser-storage';
import { setUserId } from '@lib/gtag';
import { logger } from '@lib/logger';
import { captureException } from '@lib/sentry';

interface QueryHandlerProps {
  setError?: (error: string) => void;
}

const QueryHandler: FC<QueryHandlerProps> = ({ setError }) => {
  const login = useLogin();
  const router = useRouter();
  const { query, asPath, locale = i18n.defaultLocale } = router;
  const { data: customer } = useCustomer();
  const pathname = asPath.split('?')[0];

  const handleRedirect = async (redirectTo: string): Promise<void> => {
    const { origin, pathname: redirectPathname, search } = new URL(redirectTo);
    if (origin === window?.location.origin) {
      router.push(redirectPathname + search);
    } else {
      window?.location.assign(redirectTo);
    }
  };

  const handleAuthCodeSSO = async (urlQuery: ParsedUrlQuery) => {
    const code = urlQuery?.[GlobalQueryString.AUTH_CODE] || urlQuery?.[GlobalQueryString.TOKEN];
    const payerId = urlQuery.PayerID;
    const fromPaypal = urlQuery.fromPaypal || urlQuery.paypalCancelled;

    // to skip paypal express checkout redirect
    if (code && !payerId && !fromPaypal) {
      if (!customer) {
        try {
          const { metadata } = await login({ [GlobalQueryString.AUTH_CODE]: code as string });
          if (metadata?.redirectTo) {
            await handleRedirect(metadata.redirectTo);
            return;
          }
        } catch (err) {
          captureException(err);
          logger.error(err);
          if (setError) {
            const errorCode = /.*activated\s*user.*/gi.test((err as Error)?.message)
              ? ErrorCode.USER_NOT_VERIFIED
              : ErrorCode.INVALID_TOKEN;
            setError(errorCode);
          }
        }
      }

      // remove the auth code and token from query param
      const updatedQuery = { ...urlQuery };
      delete updatedQuery[GlobalQueryString.AUTH_CODE];
      delete updatedQuery[GlobalQueryString.TOKEN];
      await router.replace({ pathname, query: updatedQuery }, undefined, { shallow: true });
    }
  };

  const storeOrderMetadata = (urlQuery: ParsedUrlQuery) => {
    if (urlQuery) {
      const metadataKeys = [GlobalQueryString.IN_APP, GlobalQueryString.REFERRER];
      const orderMetadata = metadataKeys.reduce((acc, key) => {
        if (key in urlQuery && urlQuery[key]) {
          acc[key] = urlQuery[key] as string;
        }
        return acc;
      }, {} as Record<string, string>);

      if (!isEmpty(orderMetadata)) {
        const metadataKey = getStorageKey('metadata', locale);
        const prev = getStorage(metadataKey) || {};
        setStorage(metadataKey, { ...prev, ...orderMetadata });
      }
    }
  };

  useEffect(() => {
    if (customer?.userUuid) {
      setUserId(customer?.userUuid);
    }
  }, [customer]);

  useDeepEffect(() => {
    handleAuthCodeSSO(query);
    storeOrderMetadata(query);
  }, [query]);

  return null;
};

export default QueryHandler;
